import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NProgress from "nprogress";
import "nprogress/nprogress";
import store from "../store/index";

Vue.use(VueRouter);

NProgress.configure({
  template: `<div class="bar" role="bar" style="background:#29B6F6;"><div class="peg"></div></div>
	  <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`,
  showSpinner: false,
});

const routes = [
  {
    path: "/dpp-form",
    name: "dpp-form",
    component: Home,
  },
  {
    path: "/",
    redirect: {
      path: "/dpp-form",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const doBeforeEach = async (to, from, next) => {
  NProgress.start();
  const slug = store.getters.slug;
  const applicant = store.getters.applicant;
  //console.log(store.getters.token);
  
  if (localStorage.id) {
    if (await store.dispatch("checkToken")) {
      if (Object.keys(applicant).length === 0) {
        try {
          const response = await store.dispatch(
            "userLoggedForm",
            localStorage.id
          );
          const data = response.data.data;
          if (data.locked == 0) {
            if (to.path === "/dpp-form") next();
            else next("/dpp-form");
          } else if (data.locked == 1) {
            if (to.path === "/file-upload") next();
            else next("/file-upload");
          } else if (applicant.locked == 2) {
            store.dispatch('lockedSession')
  
            location.reload()
          } else {
            // if (to.matched.some((record) => record.meta.guardIsLocked)) next();
            // else next("/success");
            store.dispatch('lockedSession')
            location.reload()
          }
        } catch (error) {
          console.log(error.response.data.message);
  
          next(`/${slug}`);
          location.reload()
          store.dispatch('logout')
        }
      } else {
        if (applicant.locked == 0) {
          if (to.path === "/dpp-form") next();
          else next("/dpp-form");
        } else if (applicant.locked == 1) {
          if (to.path === "/file-upload") next();
          else next("/file-upload");
        } else {
          // if (to.matched.some((record) => record.meta.guardIsLocked)) next();
          // else next("/");
          store.dispatch('lockedSession')
          location.reload()
        }
      }
    } else {
      next(`/${slug}`);
      location.reload()
      store.dispatch('logout')
    }
  } else {
    // if (to.matched.some((record) => record.meta.guardIsLocked))
    //     if (to.path == '/login') next();
    //     else next('/ho');
    // else {
    //     if (to.path == '/login') {
    //         next();
    //         NProgress.done();
    //     } else {
    //         next(`/` + slug + `?redirect=${to.fullPath}`);
    //         NProgress.done();
    //     }
    // }
    if (to.path === "/dpp-form" || to.path === "/file-upload") {
      next(`/${slug}`);
      location.reload();
      store.dispatch('logout')
    } else next();
  }
};

router.beforeEach(doBeforeEach);
router.afterEach(() => {
  NProgress.done();
});

export default router;
