<template>
  <section>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns is-desktop">
      <div class="column">
        <b-field>
          <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
            16 Ukuran Pakaian
          </h4>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <b-button
            class="button is-info is-pulled-right"
            @click="refresh()"
            :loading="isLoading"
          >
            Perbarui
          </b-button>
        </b-field>
      </div>
    </div>
    <form class="mt-5" ref="form">
      <div class="columns is-desktop">
        <div class="column">
          <b-field label="Ukuran Baju / Kemeja*">
            <b-select
              placeholder="Ukuran Baju / Kemeja"
              v-model="outfit.upper"
              expanded
              validation-message="Silahkan isi bagian ini"
              required
            >
              <option v-for="size in letter_sizes" :key="size">
                {{ size }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ukuran Celana*">
            <b-select
              placeholder="Ukuran Celana"
              v-model="outfit.lower"
              expanded
              validation-message="Silahkan isi bagian ini"
              required
            >
              <option v-for="size in number_sizes" :key="size">
                {{ size }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ukuran Sepatu*">
            <b-select
              placeholder="Ukuran Sepatu"
              v-model="outfit.shoe"
              expanded
              validation-message="Silahkan isi bagian ini"
              required
            >
              <option v-for="size in number_sizes" :key="size">
                {{ size }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <b-field>
        <b-button
          class="button is-info"
          @click="next"
          expanded
          :loading="isLoading"
        >
          Simpan & Lanjut
        </b-button>
      </b-field>
    </form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      letter_sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
      number_sizes: [
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
      ],
      outfit: {
        upper: null,
        lower: null,
        shoe: null,
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    async next() {
      var response = await this.update();
      if (response != false) {
        this.setStepperPosition(16);
      }
    },
    refresh() {
      this.refreshing = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/outfit`)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.id) {
              this.outfit = response.data.data;
            }
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    update() {
      if (this.$refs.form.checkValidity()) {
        this.isLoading = true;
        this.message = null;

        this.$axios
          .post(`applicants/` + this.applicant.id + `/outfit`, this.outfit)
          .then((response) => {
            this.message = response.data.message;
          })
          .catch((e) => {
            this.message = "Error: " + e.response.data.message;
            return false;
          })
          .finally(() => {
            this.isLoading = false;
            this.$buefy.snackbar.open({
              message: this.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          });
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
        return false;
      }
    },
    prev() {
      this.setStepperPosition(14);
    },
  },
};
</script>
