<template>
  <section>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns is-desktop">
      <div class="column">
        <b-field>
          <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
            14 Hobi dan Kegiatan
          </h4>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <b-button
            class="button mt-2 is-info is-pulled-right"
            @click="refresh()"
            :loading="isLoading"
          >
            Perbarui
          </b-button>
        </b-field>
      </div>
    </div>
    <div class="mt-6">
      <form ref="form">
        <b-field label="Hobi / Kegiatan di Waktu Luang*">
          <b-input
            placeholder="Hobi / Kegiatan di Waktu Luang"
            validation-message="Silahkan isi bagian ini"
            v-model="hobby"
            :maxlength="maxInputHobby"
            @keypress.native="onlyAlphaNum"
            required
          ></b-input>
        </b-field>

        <b-field label="Intensitas Membaca*">
          <b-select
            placeholder="Intensitas Membaca"
            v-model="frequency"
            expanded
            required
          >
            <option
              v-for="frequencies in reading_frequencies"
              :key="frequencies.value"
              :value="frequencies.value"
            >
              {{ frequencies.text }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Topik / Pokok Bacaan*">
          <b-input
            placeholder="Topik / Pokok Bacaan"
            validation-message="Silahkan isi bagian ini"
            v-model="topic"
            :maxlength="maxInputHobby"
            @keypress.native="onlyAlphaNum"
            required
          ></b-input>
        </b-field>
      </form>
    </div>
    <b-field>
      <b-button
        class="button is-info mt-5"
        @click="next()"
        expanded
        :loading="isLoading"
      >
        Simpan & Lanjut
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      hobby: null,
      frequency: null,
      topic: null,
      maxInputHobby: 50,
      reading_frequencies: [
        { value: "Less", text: "Sedikit" },
        { value: "Average", text: "Sedang" },
        { value: "More", text: "Banyak" },
      ],
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    async next() {
      var response = await this.update();
      if (response != false) {
        this.setStepperPosition(14);
      }
    },
    refresh() {
      this.refreshing = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/applicant-detail`)
        .then((response) => {
          if (response.data && response.data.data) {
            this.hobby = response.data.data.hobby;
          }
        })
        .catch((e) => {
          console.log(e.response.data.message);
        })
        .finally(() => {
          this.refreshing = false;
        });

      this.refreshing = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/reading-habit`)
        .then((response) => {
          if (response.data && response.data.data) {
            if (response.data.data.frequency)
              this.frequency = response.data.data.frequency;
            if (response.data.data.topic) this.topic = response.data.data.topic;
          }
        })
        .catch((e) => {
          console.log(e.response.data.message);
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    update() {
      this.hobby = this.hobby ? this.hobby.replace(/^\s*/, "") : null
      this.hobby = this.hobby != null ? this.hobby.length == 1 && (this.hobby == 0 || this.hobby == "0") ? "" : this.hobby : null
      this.hobby = this.hobby != null ? this.hobby.length > 1 && this.hobby.trim().length === 1 && (this.hobby.charAt(0) == 0 || this.hobby.charAt(0) == "0") ? "" : this.hobby : null
      this.topic = this.topic ? this.topic.replace(/^\s*/, "") : null
      this.topic = this.topic != null ? this.topic.length == 1 && (this.topic == 0 || this.topic == "0") ? "" : this.topic : null
      this.topic = this.topic != null ? this.topic.length > 1 && this.topic.trim().length === 1 && (this.topic.charAt(0) == 0 || this.topic.charAt(0) == "0") ? "" : this.topic : null

      if (this.$refs.form.checkValidity()) {
        if (!(/^[A-Za-z0-9\s,]+$/i.test(this.hobby)) || !(/^[A-Za-z0-9\s,]+$/i.test(this.topic))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Hobby dan topik bacaan hanya bisa alfabet, angka, spasi dan tanda koma",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }

        this.save_button_loading = true;
        this.message = null;

        let reading_habit = {
          frequency: this.frequency,
          topic: this.topic,
          hobby: this.hobby,
        };

        this.$axios
          .post(
            `applicants/` + this.applicant.id + `/reading-habit`,
            reading_habit
          )
          .then((response) => {
            this.message = response.data.message;
          })
          .catch((e) => {
            this.message = "Error: " + e.response.data.message;
            return false;
          })
          .finally(() => {
            this.isLoading = false;
            this.$buefy.snackbar.open({
              message: this.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          });
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
        return false;
      }
    },
    prev() {
      this.setStepperPosition(12);
    },
    onlyAlphaNum($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s,]+$/i.test(char)) { //include coma
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },
  },
};
</script>
