<template>
  <section class="hero is-light is-fullheight-with-navbar">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-size-6-mobile-tablet is-4-desktop is-8-widescreen">
            <b-step></b-step>
            <!-- <router-view></router-view>     -->
					</div>
				</div>
			</div>
		</div>
		<b-footer></b-footer>
	</section>
</template>

<script>
// import BAlert from "../components/Alert";
import BStep from "../components/Step";
import BFooter from "../components/Footer"

export default {
  components: {
    // BAlert,
    BStep,
		BFooter
  }
};
</script>

