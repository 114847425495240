<template>
  <section>
    <b-modal
      v-model="isCardModalActive"
      width="auto"
      scroll="keep"
      has-modal-card
      trap-focus
    >
      <form ref="form">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="title is-size-5-desktop is-size-6-mobile">
              {{ formTitle }}
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Organisasi*">
              <b-input
                v-model="active_activity.organization_name"
                placeholder="Nama Organisasi"
                validation-message="Silahkan isi bagian ini"
                :maxlength="maxInputSocialOrganization"
                @keypress.native="onlyAlphaNum"
                required
              ></b-input>
            </b-field>
            <b-field label="Macam Kegiatan*">
              <b-input
                v-model="active_activity.activity"
                placeholder="Macam Kegiatan"
                validation-message="Silahkan isi bagian ini"
                :maxlength="maxInputSocialOrganization"
                @keypress.native="onlyAlphaNum"
                required
              ></b-input>
            </b-field>
            <b-field label="Jabatan*">
              <b-input
                v-model="active_activity.position"
                placeholder="Jabatan"
                validation-message="Silahkan isi bagian ini"
                :maxlength="maxInputSocialOrganization"
                @keypress.native="onlyAlphaNum"
                required
              ></b-input>
            </b-field>
            <b-field label="Dari Tahun*">
              <b-input
                v-model="active_activity.start_year"
                placeholder="Dari Tahun"
                maxlength="4"
                minlength="4"
                @keypress.native="onlyNumber"
                validation-message="Silahkan isi bagian ini"
                required
              ></b-input>
            </b-field>
            <b-field label="Sampai Tahun*">
              <b-input
                v-model="active_activity.end_year"
                placeholder="Sampai Tahun"
                maxlength="4"
                minlength="4"
                @keypress.native="onlyNumber"
                validation-message="Silahkan isi bagian ini"
                required
              ></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot is-pulled-right">
            <b-button class="is-pulled-right" @click="closeModal()"
              >Kembali</b-button
            >
            <b-button
              class="is-pulled-right"
              type="is-info"
              @click.prevent="saveActivity"
              :loading="isLoading"
              >Simpan</b-button
            >
          </footer>
        </div>
      </form>
    </b-modal>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <b-field>
          <h4 class="title is-size-5-desktop is-size-6-mobile">
            13 Kegiatan Sosial / Organisasi
          </h4>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-one-quarter is-offset-9">
        <b-field>
          <b-button
            class="button is-success is-pulled-right"
            @click="add()"
            :loading="isLoading"
            expanded
          >
            Tambah Data
          </b-button>
        </b-field>
      </div>
    </div>
    <b-field>
      <b-checkbox class="mt-4" type="is-info" v-model="checkbox">
        Tidak memiliki riwayat organisasi
      </b-checkbox>
    </b-field>
    <b-table
      class="box"
      :data="activities.length == 0 ? empty : activities"
      :loading="isLoading"
    >
      <b-table-column
        label="Data Kegiatan Sosial dan Organisasi"
        :visible="activities.length == 0"
        v-slot="props"
      >
        {{ props.row }}
      </b-table-column>
      <b-table-column
        label="Nama Organisasi"
        :visible="activities.length != 0"
        v-slot="props"
      >
        {{ props.row.organization_name }}
      </b-table-column>
      <b-table-column
        :visible="activities.length != 0"
        field="action"
        label="Aksi"
        v-slot="props"
        centered
      >
        <b-button
          class="button is-warning is-light"
          @click="editActivity(props.row)"
          >Ubah</b-button
        >
      </b-table-column>
    </b-table>
    <b-field>
      <b-button
        class="button is-info"
        @click="next()"
        :loading="isLoading"
        expanded
      >
        Simpan & Lanjut
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      checkbox: false,
      isCardModalActive: false,
      edited_index: -1,
      maxInputSocialOrganization: 100,
      empty: ["Belum ada data"],
      activities: [],
      active_activity: {
        id: null,
        organization_name: null,
        activity: null,
        position: null,
        start_year: null,
        end_year: null,
      },
      active_activity_default: {
        id: null,
        organization_name: null,
        activity: null,
        position: null,
        start_year: null,
        end_year: null,
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
    formTitle() {
      return this.edited_index === -1
        ? "Tambah Data Kegiatan Sosial / Organisasi"
        : "Ubah Data Kegiatan Sosial / Organisasi";
    },
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    next() {
      if (this.activities.length === 0 && this.checkbox === false) {
        this.$buefy.snackbar.open({
          message:
            "Riwayat kegiatan sosial / organisasi masih kosong, silahkan mengisi terlebih dahulu",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else if (this.activities.length > 0 && this.checkbox === true) {
        this.$buefy.snackbar.open({
          message:
            "Silahkan memeriksa kembali riwayat kegiatan sosial / organisasi",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else {
        this.setStepperPosition(13);
      }
    },
    refresh() {
      this.isLoading = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/social-politic-activities`)
        .then((response) => {
          this.activities = response.data.data;
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    },
    add() {
      this.active_activity = Object.assign({}, this.active_activity_default);
      this.edited_index = -1;
      this.isCardModalActive = true;
    },
    closeModal() {
      this.isCardModalActive = false;
      this.active_activity = Object.assign({}, this.active_activity_default);
      this.edited_index = -1;
      this.isLoading = false;
    },
    saveActivity(e) {
      // delete leading spaces
      this.active_activity.organization_name = this.active_activity.organization_name ? this.active_activity.organization_name.replace(/^\s*/, "") : null
      this.active_activity.organization_name = this.active_activity.organization_name != null ? this.active_activity.organization_name.length == 1 && (this.active_activity.organization_name == 0 || this.active_activity.organization_name == "0") ? "" : this.active_activity.organization_name : null
      this.active_activity.organization_name = this.active_activity.organization_name != null ? this.active_activity.organization_name.length > 1 && this.active_activity.organization_name.trim().length === 1 && (this.active_activity.organization_name.charAt(0) == 0 || this.active_activity.organization_name.charAt(0) == "0") ? "" : this.active_activity.organization_name : null
      this.active_activity.activity = this.active_activity.activity ? this.active_activity.activity.replace(/^\s*/, "") : null
      this.active_activity.activity = this.active_activity.activity != null ? this.active_activity.activity.length == 1 && (this.active_activity.activity == 0 || this.active_activity.activity == "0") ? "" : this.active_activity.activity : null
      this.active_activity.activity = this.active_activity.activity != null ? this.active_activity.activity.length > 1 && this.active_activity.activity.trim().length === 1 && (this.active_activity.activity.charAt(0) == 0 || this.active_activity.activity.charAt(0) == "0") ? "" : this.active_activity.activity : null
      this.active_activity.position = this.active_activity.position ? this.active_activity.position.replace(/^\s*/, "") : null
      this.active_activity.position = this.active_activity.position != null ? this.active_activity.position.length == 1 && (this.active_activity.position == 0 || this.active_activity.position == "0") ? "" : this.active_activity.position : null
      this.active_activity.position = this.active_activity.position != null ? this.active_activity.position.length > 1 && this.active_activity.position.trim().length === 1 && (this.active_activity.position.charAt(0) == 0 || this.active_activity.position.charAt(0) == "0") ? "" : this.active_activity.position : null

      if (this.$refs.form.checkValidity()) {
        if (!(/^[A-Za-z0-9\s,]+$/i.test(this.active_activity.organization_name)) || !(/^[A-Za-z0-9\s,]+$/i.test(this.active_activity.activity)) || !(/^[A-Za-z0-9\s,]+$/i.test(this.active_activity.position))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Nama organisasi, kegiatan, dan jabatan hanya bisa alfabet, angka, spasi, dan tanda koma",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }

        if (!(/^[0-9]+$/i.test(this.active_activity.start_year)) || !(/^[0-9]+$/i.test(this.active_activity.end_year))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Tahun hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (this.active_activity.start_year > this.active_activity.end_year) {
          this.$buefy.snackbar.open({
            message: "Gagal! Tahun mulai harus lebih kecil atau sama dengan tahun selesai",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
          return false;
        }

        this.isLoading = true;
        this.message = null;
        if (this.edited_index > -1) {
          this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/social-politic-activities/` +
                this.active_activity.id,
              this.active_activity
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                Object.assign(
                  this.activities[this.edited_index],
                  this.active_activity
                );
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        } else {
          this.$axios
            .post(
              `applicants/` + this.applicant.id + `/social-politic-activities`,
              this.active_activity
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                this.active_activity.id = response.data.data.id;
                this.activities.push(this.active_activity);
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        }
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }
      e.preventDefault();
    },
    editActivity(activity) {
      this.edited_index = this.activities.indexOf(activity);
      this.active_activity = Object.assign({}, activity);
      this.isCardModalActive = true;
    },
    prev() {
      this.setStepperPosition(11);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onlyAlphaNum($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s,]+$/i.test(char)) { //include coma
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },
  },
};
</script>
