<template>
  <section>
    <b-modal
      v-model="isCardModalActive"
      width="auto"
      scroll="keep"
      has-modal-card
      trap-focus
    >
      <form ref="form">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="title is-size-5-desktop is-size-6-mobile">
              {{ formTitle }}
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Macam Bahasa*">
              <b-select
                v-model="active_language.country_code"
                placeholder="Macam Bahasa"
                @input="updateLanguageName"
                expanded
                validation-message="Silahkan isi bagian ini"
                required
              >
                <option
                  v-for="language in language_list"
                  :key="language.value"
                  :value="language.value"
                  >{{ language.text }}</option
                >
              </b-select>
            </b-field>
            <div class="columns is-desktop">
              <div class="column">
                <b-field label="Kemampuan Menulis*">
                  <b-select
                    v-model="active_language.writing_skill"
                    placeholder="Kemampuan Menulis"
                    expanded
                    validation-message="Silahkan isi bagian ini"
                    required
                  >
                    <option
                      v-for="skill in language_skill_levels"
                      :key="skill.value"
                      :value="skill.value"
                      >{{ skill.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Kemampuan Mendengar*">
                  <b-select
                    v-model="active_language.listening_skill"
                    placeholder="Kemampuan Mendengar"
                    expanded
                    validation-message="Silahkan isi bagian ini"
                    required
                  >
                    <option
                      v-for="skill in language_skill_levels"
                      :key="skill.value"
                      :value="skill.value"
                      >{{ skill.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <b-field label="Kemampuan Membaca*">
                  <b-select
                    v-model="active_language.reading_skill"
                    placeholder="Kemampuan Membaca"
                    expanded
                    validation-message="Silahkan isi bagian ini"
                    required
                  >
                    <option
                      v-for="skill in language_skill_levels"
                      :key="skill.value"
                      :value="skill.value"
                      >{{ skill.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Kemampuan Berbicara*">
                  <b-select
                    v-model="active_language.speaking_skill"
                    placeholder="Kemampuan Berbicara"
                    expanded
                    validation-message="Silahkan isi bagian ini"
                    required
                  >
                    <option
                      v-for="skill in language_skill_levels"
                      :key="skill.value"
                      :value="skill.value"
                      >{{ skill.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot is-pulled-right">
            <b-button class="is-pulled-right" @click="closeModal()"
              >Kembali</b-button
            >
            <b-button
              class="is-pulled-right"
              type="is-info"
              @click.prevent="saveLanguage"
              :loading="isLoading"
              >Simpan</b-button
            >
          </footer>
        </div>
      </form>
    </b-modal>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
          11 Pengetahuan Bahasa Asing
        </h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-notification
          type="is-danger is-light"
          aria-close-label="Close notification"
          role="alert"
          :closable="false"
        >
          <p class="is-size-7-mobile">Ketentuan pengisian form:</p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Pengetahuan Bahasa Asing Wajib Diisi - Minimal 1
          </p>
        </b-notification>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-one-quarter is-offset-9">
        <b-field>
          <b-button
            class="button is-success is-pulled-right"
            @click="add()"
            :loading="isLoading"
            expanded
          >
            Tambah Data
          </b-button>
        </b-field>
      </div>
    </div>

    <b-table class="box mt-6" :data="languages.length == 0 ? empty : languages">
      <b-table-column
        label="Data Bahasa"
        :visible="languages.length == 0"
        v-slot="props"
      >
        {{ props.row }}
      </b-table-column>
      <b-table-column
        label="Bahasa"
        :visible="languages.length != 0"
        v-slot="props"
      >
        {{ props.row.language }}
      </b-table-column>
      <b-table-column
        :visible="languages.length != 0"
        field="action"
        label="Aksi"
        v-slot="props"
        centered
      >
        <b-button
          class="button is-warning is-light"
          @click="editLanguage(props.row)"
          >Ubah</b-button
        >
      </b-table-column>
    </b-table>
    <b-field>
      <b-button
        class="button is-info"
        @click="next"
        expanded
        :loading="isLoading"
      >
        Simpan & Lanjut
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isCardModalActive: false,
      isLoading: false,
      edited_index: -1,
      language_skill_levels: [
        { value: "Poor", text: "Kurang" },
        { value: "Average", text: "Cukup" },
        { value: "Good", text: "Baik" },
        { value: "Excellent", text: "Sangat Baik" },
      ],
      language_list: [
        { value: "SAU", text: "Bahasa Arab" },
        { value: "NLD", text: "Bahasa Belanda" },
        { value: "USA", text: "Bahasa Inggris" },
        { value: "ITA", text: "Bahasa Italia" },
        { value: "JPN", text: "Bahasa Jepang" },
        { value: "DEU", text: "Bahasa Jerman" },
        { value: "KOR", text: "Bahasa Korea" },
        { value: "MYS", text: "Bahasa Malaysia" },
        { value: "CHN", text: "Bahasa Mandarin" },
        { value: "FRA", text: "Bahasa Perancis" },
        { value: "PRT", text: "Bahasa Portugal" },
        { value: "RUS", text: "Bahasa Rusia" },
        { value: "ESP", text: "Bahasa Spanyol" },
        { value: "THA", text: "Bahasa Thailand" },
      ],
      empty: ["Belum ada data"],
      languages: [],
      active_language: {
        id: null,
        language: null,
        country_code: null,
        listening_skill: null,
        reading_skill: null,
        speaking_skill: null,
        writing_skill: null,
      },
      active_language_default: {
        id: null,
        language: null,
        country_code: null,
        listening_skill: null,
        reading_skill: null,
        speaking_skill: null,
        writing_skill: null,
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
    formTitle() {
      return this.edited_index === -1
        ? "Tambah Data Pengetahuan Bahasa Asing"
        : "Ubah Data Pengetahuan Bahasa Asing";
    },
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    next() {
      if (this.languages.length > 0) {
        this.setStepperPosition(11);
      } else {
        this.$buefy.snackbar.open({
          message:
            "Pengetahuan bahasa asing belum terisi, silahkan isi sebelum melanjutkan",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }
    },
    updateLanguageName() {
      for (var i = 0; i < this.language_list.length; i++) {
        if (this.language_list[i].value === this.active_language.country_code) {
          this.active_language.language = this.language_list[i].text;
          break;
        }
      }
    },
    add() {
      this.isCardModalActive = true;
      this.active_language = Object.assign({}, this.active_language_default);
    },
    refresh() {
      this.refreshing = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/language-skills`)
        .then((response) => {
          this.languages = response.data.data;
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    closeModal() {
      this.isCardModalActive = false;
      this.active_language = Object.assign({}, this.active_language_default);
      this.edited_index = -1;
      this.isLoading = false;
    },
    saveLanguage(e) {
      if (this.$refs.form.checkValidity()) {
        this.isLoading = true;
        this.message = null;
        if (this.edited_index > -1) {
          this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/language-skills/` +
                this.active_language.id,
              this.active_language
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                Object.assign(
                  this.languages[this.edited_index],
                  this.active_language
                );
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            })
            .finally(() => {
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        } else {
          this.$axios
            .post(
              `applicants/` + this.applicant.id + `/language-skills`,
              this.active_language
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                this.active_language.id = response.data.data.id;
                this.languages.push(this.active_language);
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        }
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }
      e.preventDefault();
    },
    editLanguage(language) {
      this.edited_index = this.languages.indexOf(language);
      this.active_language = Object.assign({}, language);
      this.isCardModalActive = true;
    },
    prev() {
      this.setStepperPosition(9);
    },
  },
};
</script>
