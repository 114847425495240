<template>
	<section>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns is-desktop">
			<div class="column">
				<b-field>
					<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
						15 Riwayat Kesehatan
					</h4>
				</b-field>
			</div>
			<div class="column">
				<b-field>
					<b-button  
						class="button mt-2 is-info is-pulled-right" 
						@click="refresh" 
						:loading="isLoading"
					>
						Perbarui
					</b-button>
				</b-field>
			</div>
		</div>
		<b-tabs class="mt-6" v-model="tabs">
			<b-tab-item label="Bagian 1">
				<form ref="partOne">
					<div class="columns is-desktop">
						<div class="column">
							<b-field label="Tinggi Badan*">
								<b-input 
									placeholder="cm" 
									v-model="height"
									@keypress.native="onlyNumber"
									validation-message="Silahkan isi bagian ini"
									required
								></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field label="Berat Badan*">
								<b-input 
									placeholder="kg" 
									v-model="weight"
									@keypress.native="onlyNumber"
									validation-message="Silahkan isi bagian ini"
									required
								></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field label="Golongan Darah*">
								<b-select
									placeholder="Golongan Darah" 
									v-model="blood_type"
									expanded
									validation-message="Silahkan isi bagian ini"
									required
								>
									<option value="A">A</option>
									<option value="B">B</option>
									<option value="AB">AB</option>
									<option value="O">0</option>
								</b-select>
							</b-field>
						</div>
					</div>	
					<div class="">
						<b-field label="Apakah anda pernah/sedang menderita sakit keras/kronis/kecelakaan berat/operasi/opname/patah tulang? Kapan dan macam apa? Jelaskan!*"></b-field>
						<div class="field">
							<b-radio 
								v-model="serious_illness"
								name="seriousIllness"
								type="is-info"
								native-value="Y"
								validation-message="Silahkan isi bagian ini"
								required
							>
								Ya
							</b-radio>
						</div>
						<div class="field">
							<b-radio 
								v-model="serious_illness"
								name="seriousIllness"
								type="is-info"
								native-value="T"
								required	
							>
								Tidak
							</b-radio>
						</div>
					</div>
					<b-field>
						<b-input
							v-if="serious_illness === 'Y'"	
				:disabled="serious_illness != 'Y'"
							placeholder="Keterangan"
							v-model="serious_illness_description"
							validation-message="Silahkan isi bagian ini"
							:maxlength="maxInputSeriousIlness"
							@keypress.native="onlyAlphaNum"
							required
						></b-input>
					</b-field>
					<div class="block">
						<b-field label="Apakah anda menghidap penyakit:"></b-field>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Asma*">
									<b-radio 
										v-model="illness_history.asthma"
										name="asthma"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio v-model="illness_history.asthma"
										name="asthma"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Hepatitis*">
									<b-radio
										name="hepatitic" 
										v-model="illness_history.hepatitic"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.hepatitic"
										name="hepatitic"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
							<div class="column">
								<b-field label="TBC*">
									<b-radio 
										v-model="illness_history.tbc"
										name="tbc"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.tbc"
										name="tbc"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>							
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Jantung*">
									<b-radio 
										v-model="illness_history.heart"
										name="heart"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.heart"
										name="heart"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Epilepsi*">
									<b-radio 
										v-model="illness_history.epilepsy"
										name="epilepsy"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.epilepsy"
										name="epilepsy"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Maag*">
									<b-radio 
										v-model="illness_history.maag"
										name="maag"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.maag"
										name="maag"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
						</div>
						<div class="columns">
							<div class="column is-one-third">
								<b-field label="Hiportermia*">
									<b-radio 
										v-model="illness_history.hypotermia"
										name="hipotermia"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.hypotermia"
										name="hipotermia"										
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Alergi*">
									<b-radio 
										v-model="illness_history.allergic"
										name="alergic"
										type="is-info"
										native-value="Y"
										required	
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="illness_history.allergic"
										name="alergic"
										type="is-info"
										native-value="T"
										required	
									>
										Tidak
									</b-radio>
								</b-field>
								<b-field>
									<b-input 
										v-model="illness_history.allergic_description"
										v-if="illness_history.allergic === 'Y'"
										:disabled="illness_history.allergic != 'Y'"
										placeholder="Alergi Terhadap"
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputAllergy"
										@keypress.native="onlyAlphaNum"
										expanded
										required 
									></b-input>
								</b-field>
							</div>
						</div>
					</div>
					<b-field label="Riwayat penyakit lain yang belum disebutkan?">
						<b-input 
							placeholder="Penyakit lain" 
							v-model="illness_history.other"
							:maxlength="maxInputIlnessHistory"
							@keypress.native="onlyAlphaNum"
						></b-input>
					</b-field>
					<b-field>
						<b-button  
							class="button is-info mt-5" 
							@click="partTwo" 
							expanded
							:loading="isLoading"
						>
							Bagian 2
						</b-button>
					</b-field>
				</form>
			</b-tab-item>
			<b-tab-item 
				:disabled="tabs_disabled" 
				:value="'1'" 
				label="Bagian 2"
			>
				<form ref="form">
					<label class="label">
						Apakah ada masalah dengan penglihatan anda?
					</label>
						<div class="columns is-desktop">
							<div class="column is-one-quarter">
								<b-field class="mt-2">
									<b-checkbox
										v-model="vision.vision_problem"
										native-value="Buta Warna"
										type="is-info"
									>
										Buta Warna
									</b-checkbox>
								</b-field>
							</div>
							<div class="column" v-if="vision.vision_problem.includes('Buta Warna')">
								<b-field message="Contoh: parsial/total">
									<b-input
										class="mr-4"
										v-model="vision.color_blind"
										:disabled="!vision.vision_problem.includes('Buta Warna')" 
										placeholder="Keterangan"
										validation-message="Silahkan isi bagian ini" 
										:maxlength="maxInputColorBlind"
										@keypress.native="visionValidation"
										required
										expanded
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-one-quarter">
								<b-field class="mt-2">
									<b-checkbox
										v-model="vision.vision_problem"
										native-value="Minus"
										type="is-info"
									>
										Minus
									</b-checkbox>
								</b-field>
							</div>
							<div class="column" v-if="vision.vision_problem.includes('Minus')">
								<b-field message="Contoh: kanan(1,5), kiri(2)">
									<b-input
										class="mr-4"
										v-model="vision.minus"
										:disabled="!vision.vision_problem.includes('Minus')"  
										placeholder="kanan(1,5), kiri(2)"
										validation-message="Silahkan isi bagian ini" 
										:maxlength="maxInputAnotherVision"
										@keypress.native="visionValidation"
										required
										expanded
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-one-quarter">
								<b-field class="mt-2">
									<b-checkbox
										v-model="vision.vision_problem"
										native-value="Plus"
										type="is-info"
									>
										Plus
									</b-checkbox>
								</b-field>
							</div>
							<div class="column" v-if="vision.vision_problem.includes('Plus')">
								<b-field message="Contoh: kanan(2), kiri(2)">
									<b-input 
										class="mr-4"
										v-model="vision.plus"
										:disabled="!vision.vision_problem.includes('Plus')" 
										placeholder="kanan(2), kiri(2)" 
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputAnotherVision"
										@keypress.native="visionValidation"
										required
										expanded
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column is-one-quarter">
								<b-field class="mt-2">
									<b-checkbox
										v-model="vision.vision_problem"
										native-value="Silinder"
										type="is-info"
									>
										Silinder
									</b-checkbox>
								</b-field>
							</div>
							<div class="column" v-if="vision.vision_problem.includes('Silinder')">
								<b-field message="kanan(1), kiri(1,5)">
									<b-input 
										class="mr-4"
										v-model="vision.cylinder"
										:disabled="!vision.vision_problem.includes('Silinder')" 
										placeholder="kanan(1), kiri(1,5)" 
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputAnotherVision"
										@keypress.native="visionValidation"
										required
										expanded
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="block">
							<b-field label="Apakah anda seorang (yang/menggunakan):"></b-field>
							<div class="columns is-desktop">
								<div class="column">
									<div class="columns is-mobile">
										<div class="column is-two-quarter">
											<b-field label="Perokok? *">
												<b-radio 
													v-model="smoker"	
													type="is-info"
													native-value="Y"
													name="smoker"
													required>
													Ya
												</b-radio>
											</b-field>
											<b-field>
												<b-radio 
													v-model="smoker"
													type="is-info"
													native-value="T"
													name="smoker"
													required
												>
													Tidak
												</b-radio>
											</b-field>
										</div>
										<div class="column is-one-quarter">
											<b-tooltip 
												label="Jumlah dalam sehari*"
												position="is-bottom"
												type="is-info"
											>
												<b-field class="mt-5">
													<b-input
														@keypress.native="onlyNumber"
														v-if="smoker === 'Y'"
														:disabled="smoker != 'Y'"
														v-model="smoking_quantity" 
														placeholder="Jumlah Sehari"
														validation-message="Silahkan isi bagian ini" 
														expanded
														required
													></b-input>
												</b-field>
											</b-tooltip>
										</div>
										<div class="column">
											<b-field class="mt-5">
												<b-select
													v-if="smoker === 'Y'"
													:disabled="smoker != 'Y'"
													placeholder="Satuan" 
													v-model="smoking_unit"
													expanded
													required
												>
													<option value="Batang">Batang</option>
													<option value="Bungkus">Bungkus</option>
												</b-select>
											</b-field>											
										</div>
									</div>
								</div>
								<div class="column">
									<div class="columns is-mobile">
										<div class="column is-two-quarter">
											<b-field label="Alkoholik?*">
												<b-radio 
													v-model="alcoholic"
													type="is-info"
													native-value="Y"
													name="alcoholic"
													required
												>
													Ya
												</b-radio>
											</b-field>
											<b-field>
												<b-radio 
													v-model="alcoholic"
													type="is-info"
													native-value="T"
													name="alcoholic"
													required
												>
													Tidak
												</b-radio>
											</b-field>
										</div>
										<div class="column">
											<b-field class="mt-5">
												<b-input
													v-if="alcoholic === 'Y'"
													:disabled="alcoholic != 'Y'"
													v-model="alcoholic_description" 
													placeholder="Jenis minuman" 
													validation-message="Silahkan isi bagian ini"
													:maxlength="maxInputAlcoholic"
													@keypress.native="onlyAlphaNum"
													expanded
													required
												></b-input>
											</b-field>
										</div>
									</div>
								</div>
							</div>
							<div class="column">
									<div class="columns is-mobile">
										<div class="column is-two-quarter">
											<b-field label="Bertato?*">
												<b-radio 
													v-model="tattoo"
													type="is-info"
													native-value="Y"
													name="tattoo"
													required
												>
													Ya
												</b-radio>
											</b-field>
											<b-field>
												<b-radio 
													v-model="tattoo"
													type="is-info"
													native-value="T"
													name="tattoo"
													required
												>
													Tidak
												</b-radio>
											</b-field>
										</div>
										<div class="column">
											<b-field class="mt-5">
												<b-tooltip
													label="Berapa banyak dan dibagian mana saja*"
													position="is-bottom"
													type="is-info"
												>
													<b-input
														v-if="tattoo === 'Y'"
														:disabled="tattoo != 'Y'"
														v-model="tattoo_description" 
														placeholder="Berapa banyak dan dibagian mana saja"
														validation-message="Silahkan isi bagian ini"
														:maxlength="maxInputTattoo"
														@keypress.native="onlyAlphaNum"
														expanded
														required
													></b-input>
												</b-tooltip>
											</b-field>
										</div>
									</div>
								</div>
								<div class="column">
									<div class="columns is-mobile">
										<div class="column is-two-quarter">
											<b-field label="Narkoba?*">
												<b-radio 
													v-model="drug_user"
													type="is-info"
													native-value="Y"
													name="drugs"
													required
												>
													Ya
												</b-radio>
											</b-field>
											<b-field>
												<b-radio 
													v-model="drug_user"
													type="is-info"
													native-value="T"
													name="drugs"
													required
												>
													Tidak
												</b-radio>
											</b-field>
										</div>
										<div class="column">
											<b-field class="mt-5">
												<b-tooltip
													label="Tahun Penggunaan*"
													position="is-bottom"
													type="is-info"
												>
													<b-input
														@keypress.native="onlyNumber"
														v-if="drug_user === 'Y'"
														:disabled="drug_user != 'Y'"
														v-model="drug_user_description" 
														placeholder="Tahun Penggunaan" 
														validation-message="Silahkan isi bagian ini"
														minlength='4'
														maxlength='4'
														expanded
														required
													></b-input>
												</b-tooltip>
											</b-field>
										</div>
									</div>
								</div>
						</div>
						<b-field>
							<b-button  
								class="button is-info" 
								@click="next()" 
								expanded
								:loading="isLoading"
							>
								Simpan & Lanjut
							</b-button>
						</b-field>
				</form>
			</b-tab-item>
		</b-tabs>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
		return {
			isLoading: false,
			tabs: undefined,
			tabs_disabled: true,

			checkbox: false,
			height: null,
      weight: null,
      vision: {
        color_blind: null,
        vision_problem: [],
        minus: null,
        plus: null,
        cylinder: null
      },
      serious_illness: null,
      serious_illness_description: null,
      illness_history: {
        asthma: null,
        hepatitic: null,
        tbc: null,
        heart: null,
        epilepsy: null,
        hypotermia: null,
        allergic: null,
        allergic_description: null,
        maag: null,
        other: null
      },	  
		maxInputSeriousIlness:60,
		maxInputIlnessHistory:50,
		maxInputAllergy:50,
		maxInputAlcoholic: 50,
		maxInputTattoo: 50,
		maxInputColorBlind: 10,
		maxInputAnotherVision: 20,
      // other_serious_illness: null,
      smoker: null,
      smoking_quantity: null,
      smoking_unit: null,
      alcoholic: null,
      alcoholic_description: null,
      drug_user: null,
      drug_user_description: null,
      blood_type: null,
      tattoo: null,
      tattoo_description: null,
		}
	},
	async mounted() {
		await this.refresh()
	},
	computed: {
		...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position"
    })
	},
	methods: {
		...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition"
		}),
		async next() {
      var response = await this.update();
      if (response != false) {
        this.setStepperPosition(15);
      }
    },
    refresh() {
      this.isLoading = true;
      this.$axios
        .get("applicants/" + this.applicant.id + `/medical-history`)
        .then(response => {
          let data = response.data.data;

          this.height = data.height;
          this.weight = data.weight;
          this.blood_type = data.blood_type;

          if (data.serious_illness) {
            if (data.serious_illness === "T") {
              this.serious_illness = data.serious_illness;
              this.serious_illness_description = null;
            } else {
              this.serious_illness = "Y";
              this.serious_illness_description = data.serious_illness;
            }
          }

          this.vision = JSON.parse(data.vision_problem);
          // this.other_serious_illness = data.other_serious_illness
          this.illness_history = JSON.parse(data.illness_history);

          if (data.alcoholic === "T") {
            this.alcoholic = data.alcoholic;
            this.alcoholic_description = null;
          } else {
            this.alcoholic = "Y";
            this.alcoholic_description = data.alcoholic;
          }

          if (data.drug_user === "T") {
            this.drug_user = data.drug_user;
            this.drug_user_description = null;
          } else {
            this.drug_user = "Y";
            this.drug_user_description = data.drug_user;
          }

          if (data.tattoo === "T") {
            this.tattoo = data.tattoo;
            this.tattoo_description = null;
          } else {
            this.tattoo = "Y";
            this.tattoo_description = data.tattoo;
          }

          let smoker_temp = JSON.parse(data.smoker);

          if (smoker_temp) {
            if (smoker_temp.smoker && smoker_temp.smoker === "T") {
              this.smoker = smoker_temp.smoker;
              this.smoking_unit = null;
              this.smoking_quantity = null;
            } else {
              this.smoker = "Y";
              this.smoking_unit = smoker_temp.smoking_unit;
              this.smoking_quantity = smoker_temp.smoking_quantity;
            }
          }
        })
        .catch(e => {
          // this.message = "Error: " + e.response.data.message
          console.log(e.response.data.message);

          // this.$buefy.snackbar.open({
						// 	message: this.message,
						// 	type: 'is-info',
						// 	position: 'is-top',
						// 	actionText: 'Tutup',
						// })
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
		this.serious_illness_description = this.serious_illness_description ? this.serious_illness_description.replace(/^\s*/, "") : null
		this.serious_illness_description = this.serious_illness_description != null ? this.serious_illness_description.length == 1 && (this.serious_illness_description == 0 || this.serious_illness_description == "0") ? "" : this.serious_illness_description : null
this.serious_illness_description = this.serious_illness_description != null ? this.serious_illness_description.length > 1 && this.serious_illness_description.trim().length === 1 && (this.serious_illness_description.charAt(0) == 0 || this.serious_illness_description.charAt(0) == "0") ? "" : this.serious_illness_description : null
		this.illness_history.allergic_description = this.illness_history.allergic_description ? this.illness_history.allergic_description.replace(/^\s*/, "") : null
		this.illness_history.allergic_description = this.illness_history.allergic_description != null ? this.illness_history.allergic_description.length == 1 && (this.illness_history.allergic_description == 0 || this.illness_history.allergic_description == "0") ? "" : this.illness_history.allergic_description : null
this.illness_history.allergic_description = this.illness_history.allergic_description != null ? this.illness_history.allergic_description.length > 1 && this.illness_history.allergic_description.trim().length === 1 && (this.illness_history.allergic_description.charAt(0) == 0 || this.illness_history.allergic_description.charAt(0) == "0") ? "" : this.illness_history.allergic_description : null
		this.illness_history.other = this.illness_history.other ? this.illness_history.other.replace(/^\s*/, "") : null
		this.illness_history.other = this.illness_history.other != null ? this.illness_history.other.length == 1 && (this.illness_history.other == 0 || this.illness_history.other == "0") ? "" : this.illness_history.other : null
this.illness_history.other = this.illness_history.other != null ? this.illness_history.other.length > 1 && this.illness_history.other.trim().length === 1 && (this.illness_history.other.charAt(0) == 0 || this.illness_history.other.charAt(0) == "0") ? "" : this.illness_history.other : null
		this.vision.color_blind = this.vision.color_blind ? this.vision.color_blind.replace(/^\s*/, "") : null
		this.vision.color_blind = this.vision.color_blind != null ? this.vision.color_blind.length == 1 && (this.vision.color_blind == 0 || this.vision.color_blind == "0") ? "" : this.vision.color_blind : null
this.vision.color_blind = this.vision.color_blind != null ? this.vision.color_blind.length > 1 && this.vision.color_blind.trim().length === 1 && (this.vision.color_blind.charAt(0) == 0 || this.vision.color_blind.charAt(0) == "0") ? "" : this.vision.color_blind : null
		this.vision.minus = this.vision.minus ? this.vision.minus.replace(/^\s*/, "") : null
		this.vision.minus = this.vision.minus != null ? this.vision.minus.length == 1 && (this.vision.minus == 0 || this.vision.minus == "0") ? "" : this.vision.minus : null
this.vision.minus = this.vision.minus != null ? this.vision.minus.length > 1 && this.vision.minus.trim().length === 1 && (this.vision.minus.charAt(0) == 0 || this.vision.minus.charAt(0) == "0") ? "" : this.vision.minus : null
		this.vision.plus = this.vision.plus ? this.vision.plus.replace(/^\s*/, "") : null
		this.vision.plus = this.vision.plus != null ? this.vision.plus.length == 1 && (this.vision.plus == 0 || this.vision.plus == "0") ? "" : this.vision.plus : null
this.vision.plus = this.vision.plus != null ? this.vision.plus.length > 1 && this.vision.plus.trim().length === 1 && (this.vision.plus.charAt(0) == 0 || this.vision.plus.charAt(0) == "0") ? "" : this.vision.plus : null
		this.vision.cylinder = this.vision.cylinder ? this.vision.cylinder.replace(/^\s*/, "") : null
		this.vision.cylinder = this.vision.cylinder != null ? this.vision.cylinder.length == 1 && (this.vision.cylinder == 0 || this.vision.cylinder == "0") ? "" : this.vision.cylinder : null
this.vision.cylinder = this.vision.cylinder != null ? this.vision.cylinder.length > 1 && this.vision.cylinder.trim().length === 1 && (this.vision.cylinder.charAt(0) == 0 || this.vision.cylinder.charAt(0) == "0") ? "" : this.vision.cylinder : null
		this.alcoholic_description = this.alcoholic_description ? this.alcoholic_description.replace(/^\s*/, "") : null
		this.alcoholic_description = this.alcoholic_description != null ? this.alcoholic_description.length == 1 && (this.alcoholic_description == 0 || this.alcoholic_description == "0") ? "" : this.alcoholic_description : null
this.alcoholic_description = this.alcoholic_description != null ? this.alcoholic_description.length > 1 && this.alcoholic_description.trim().length === 1 && (this.alcoholic_description.charAt(0) == 0 || this.alcoholic_description.charAt(0) == "0") ? "" : this.alcoholic_description : null
		this.tattoo_description = this.tattoo_description ? this.tattoo_description.replace(/^\s*/, "") : null
		this.tattoo_description = this.tattoo_description != null ? this.tattoo_description.length == 1 && (this.tattoo_description == 0 || this.tattoo_description == "0") ? "" : this.tattoo_description : null
this.tattoo_description = this.tattoo_description != null ? this.tattoo_description.length > 1 && this.tattoo_description.trim().length === 1 && (this.tattoo_description.charAt(0) == 0 || this.tattoo_description.charAt(0) == "0") ? "" : this.tattoo_description : null
		this.smoking_quantity = this.smoking_quantity != null ? this.smoking_quantity.length == 1 && (this.smoking_quantity == 0 || this.smoking_quantity == "0") ? "" : this.smoking_quantity : null
this.smoking_quantity = this.smoking_quantity != null ? this.smoking_quantity.length > 1 && this.smoking_quantity.trim().length === 1 && (this.smoking_quantity.charAt(0) == 0 || this.smoking_quantity.charAt(0) == "0") ? "" : this.smoking_quantity : null
		this.height = this.height != null ? this.height.length == 1 && (this.height == 0 || this.height == "0") ? "" : this.height : null
		this.height = this.height != null ? this.height.length > 1 && this.height.trim().length === 1 && (this.height.charAt(0) == 0 || this.height.charAt(0) == "0") ? "" : this.height : null
		this.weight = this.weight != null ? this.weight.length == 1 && (this.weight == 0 || this.weight == "0") ? "" : this.weight : null
this.weight = this.weight != null ? this.weight.length > 1 && this.weight.trim().length === 1 && (this.weight.charAt(0) == 0 || this.weight.charAt(0) == "0") ? "" : this.weight : null

		if(!this.$refs.partOne.checkValidity()) {
			this.$buefy.snackbar.open({
				message: 'Gagal! Data bagian 1 kurang/tidak valid, silahkan cek kembali',
				type: 'is-info',
				position: 'is-top',
				actionText: 'Tutup',})
			return false;
		} else {
			if (this.$refs.form.checkValidity()) {
				if (!(/^[0-9]+$/i.test(this.height)) || !(/^[0-9]+$/i.test(this.weight))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Tinggi dan berat badan hanya bisa angka",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}
				if ((!(/^[A-Za-z0-9\s,]+$/i.test(this.serious_illness_description)) && this.serious_illness == 'Y') || 
					(!(/^[A-Za-z0-9\s,]+$/i.test(this.illness_history.allergic_description)) && this.illness_history.allergic == 'Y') || 
					!(/^[A-Za-z0-9\s,]+$/i.test(this.illness_history.other))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Sakit kronis, alergi, dan riwayat penyakit lain hanya bisa alfabet, angka, spasi, dan tanda koma",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}

				if ((!(/^[A-Za-z0-9\s,()]+$/i.test(this.vision.color_blind)) && this.vision.vision_problem.includes("Buta Warna")) ||
					(!(/^[A-Za-z0-9\s,()]+$/i.test(this.vision.minus)) && this.vision.vision_problem.includes("Minus")) ||
					(!(/^[A-Za-z0-9\s,()]+$/i.test(this.vision.plus)) && this.vision.vision_problem.includes("Plus")) ||
					(!(/^[A-Za-z0-9\s,()]+$/i.test(this.vision.cylinder)) && this.vision.vision_problem.includes("Silinder"))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Masalah penglihatan hanya bisa alfabet, angka, spasi, tanda koma, dan tanda kurung",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}

				if ((this.smoker === 'Y' && !(/^[0-9]+$/i.test(this.smoking_quantity))) || (this.drug_user === 'Y' && !(/^[0-9]+$/i.test(this.drug_user_description)))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Jumlah rokok dan tahun penggunaan narkoba hanya bisa angka",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}

				if ((this.alcoholic == 'Y' && !(/^[A-Za-z0-9\s,]+$/i.test(this.alcoholic_description))) || (this.tattoo == 'Y' && !(/^[A-Za-z0-9\s,]+$/i.test(this.tattoo_description)))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Keterangan alkohol dan tato hanya bisa alfabet, angka, spasi, dan tanda koma",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}

				this.isLoading = true;
				this.message = null;

				if (this.illness_history.allergic !== 'Y') {
				this.illness_history.allergic_description = null;
				}

				if (!this.vision.vision_problem.includes("Minus")) {
				this.vision.minus = null;
				}
				if (!this.vision.vision_problem.includes("Plus")) {
				this.vision.plus = null;
				}
				if (!this.vision.vision_problem.includes("Buta Warna")) {
				this.vision.color_blind = null;
				}

				if (!this.vision.vision_problem.includes("Silinder")) {
				this.vision.cylinder = null;
				}

				let medical_history = {
				height: this.height,
				weight: this.weight,
				blood_type: this.blood_type,
				serious_illness:
					this.serious_illness === "Y" &&
					this.serious_illness_description.length > 0
					? this.serious_illness_description
					: "T",
				vision_problem: this.vision,
				// other_serious_illness: this.other_serious_illness,
				illness_history: this.illness_history,
				alcoholic:
					this.alcoholic === "Y" && this.alcoholic_description.length > 0
					? this.alcoholic_description
					: "T",
				drug_user:
					this.drug_user === "Y" && this.drug_user_description.length > 0
					? this.drug_user_description
					: "T",
				tattoo:
					this.tattoo === "Y" && this.tattoo_description.length > 0
					? this.tattoo_description
					: "T",
				smoker:
					this.smoker === "Y"
					? {
						smoking_unit: this.smoking_unit,
						smoking_quantity: this.smoking_quantity
						}
					: { smoker: this.smoker }

				// serious_illness_description: null,
				// other_serious_illness: null,
				// smoker: null,
				// alcoholic: null,
				// drug_user: null,
				// tattoo: null,
				};

				this.$axios
				.post(
					`applicants/` + this.applicant.id + `/medical-history`,
					medical_history
				)
				.then(response => {
					this.message = response.data.message;
				})
				.catch(e => {
					this.message = "Error: " + e.response.data.message;
				})
				.finally(() => {
					this.isLoading = false;
					this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
								})
				});
			} else {
				this.message = "Gagal! Data bagian 2 kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
						})
				return false;
			}
		}
      
		},
		prev() {
			if (this.tabs == 1)
				this.tabs = undefined
			else
				this.setStepperPosition(13)
		},
		partTwo() {
			this.serious_illness_description = this.serious_illness_description ? this.serious_illness_description.replace(/^\s*/, "") : null
			this.illness_history.allergic_description = this.illness_history.allergic_description ? this.illness_history.allergic_description.replace(/^\s*/, "") : null
			this.illness_history.other = this.illness_history.other ? this.illness_history.other.replace(/^\s*/, "") : null

			if(this.$refs.partOne.checkValidity())  {
				if (!(/^[0-9]+$/i.test(this.height)) || !(/^[0-9]+$/i.test(this.weight))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Tinggi dan berat badan hanya bisa angka",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}
				if (!(/^[A-Za-z0-9\s,]+$/i.test(this.serious_illness_description)) && this.serious_illness == 'Y') {
					this.$buefy.snackbar.open({
						message: "Gagal! Sakit kronis hanya bisa alfabet, angka, spasi, dan tanda koma",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}
				if (!(/^[A-Za-z0-9\s,]+$/i.test(this.illness_history.allergic_description)) && this.illness_history.allergic == 'Y') {
					this.$buefy.snackbar.open({
						message: "Gagal! Alergi hanya bisa alfabet, angka, spasi, dan tanda koma",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}
				if (!(/^[A-Za-z0-9\s,]+$/i.test(this.illness_history.other))) {
					this.$buefy.snackbar.open({
						message: "Gagal! Riwayat penyakit lain hanya bisa alfabet, angka, spasi, dan tanda koma",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false;
				}

				this.tabs_disabled = false,
				this.tabs = 1
			} else {
				this.$buefy.snackbar.open({
          message: 'Gagal! Data kurang/tidak valid, silahkan cek kembali',
          type: 'is-info',
          position: 'is-top',
          actionText: 'Tutup',
        })
			}
		},
		nonSmoker() {
			this.smoking_quantity = '',
			this.smoking_unit = ''
		},
		onlyNumber($event) {
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;
			if (keyCode < 48 || keyCode > 57) {
				// 46 is dot
				$event.preventDefault();
			}
		},
		onlyAlphaNum($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z0-9\s,]+$/i.test(char)) { //include coma
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
		visionValidation($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z0-9\s,()]+$/i.test(char)) { //include coma and parentheses
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
	},
};
</script>