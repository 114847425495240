<template>
  <section>
    <b-alert></b-alert>
    <b-steps
      class="box is-small"
      type="is-info"
      position="is-left"
      v-model="step"
      :animated="true"
      :rounded="true"
      :has-navigation="false"
      :mobile-mode="mobileMode"
    >
      <template v-for="(step, index) in steps">
        <b-step-item
          class="mt-5"
          v-if="step.displayed"
          :clickable="applicant.checkpoint !== false && !isLoading"
          :key="index"
          :step="step.step"
          :label="step.label"
        >
          <!-- <div class="columns is-mobile mt-2">
						<div class="column">
							<b-field v-if="stepper_position > 0">
								<b-button
									class="is-text"
									icon-left="chevron-left"
									@click="prev()"
								>
									Sebelumnya
								</b-button>
							</b-field>
						</div>
						<div class="column"> -->
          <b-dropdown
            v-show="step > 16 || (applicant.checkpoint !== false && !isLoading)"
            class="is-pulled-right"
            aria-role="list"
          >
            <button
              class="button is-info"
              slot="trigger"
              slot-scope="{ active }"
            >
              <span>Ke Bagian</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>

            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(0)"
            >
              Data Pribadi
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(1)"
            >
              Kartu Identitas
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(2)"
            >
              Kontak dan Media Sosial
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(3)"
            >
              Kendaraan
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(4)"
            >
              Alamat
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(5)"
            >
              Keluarga Inti
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(6)"
            >
              Kontak Darurat Selain Keluarga Inti
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(7)"
            >
              Referensi
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(8)"
            >
              Riwayat Pendidikan Formal
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(9)"
            >
              Riwayat Pendidikan Informal/Kursus
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(10)"
            >
              Pengetahuan Bahasa Asing
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(11)"
            >
              Pengalaman Kerja
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(12)"
            >
              Kegiatan Sosial / Organisasi
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(13)"
            >
              Hobi dan Kegiatan
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(14)"
            >
              Riwayat Kesehatan
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(15)"
            >
              Ukuran Pakaian
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(16)"
            >
              Pertanyaan Lain
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="setStepperPosition(17)"
            >
              Pernyataan dan Kesepakatan
            </b-dropdown-item>
          </b-dropdown>
          <!-- </div>
					</div> -->
          <component :is="step.content"></component>
        </b-step-item>
      </template>
    </b-steps>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import BAlert from "../components/Alert";
import PersonalData from "../components/PersonalData";
import IdentityCard from "../components/IdentityCard";
import Contact from "../components/Contact";
import Vehicle from "../components/Vehicle";
import BAddress from "../components/Address";
import BFamily from "../components/Family";
import EmergencyContacts from "../components/EmergencyContacts";
import Reference from "../components/Reference";
import FormalEducation from "../components/FormalEducation";
import InformalEducation from "../components/InformalEducation";
import ForeignLanguange from "../components/ForeignLanguange";
import WorkExperience from "../components/WorkExperience";
import SocialOrganization from "../components/SocialOrganization";
import Hobby from "../components/Hobby";
import HealthHistory from "../components/HealthHistory";
import Outfit from "../components/Outfit";
import OtherQuestion from "../components/OtherQuestion";
import Signature from "../components/Signature";

export default {
  data() {
    return {
      mobileMode: "minimalist",
      value: null,
      text: "",

      applicant_details: {},
    };
  },
  async mounted() {
    console.log(this.isLoading);

    await this.fetchApplicant();
    // await this.fetchApplicantDetail();
    // await this.stepStatus();
  },
  components: {
    BAlert,
    PersonalData,
    IdentityCard,
    Contact,
    Vehicle,
    BAddress,
    BFamily,
    EmergencyContacts,
    Reference,
    FormalEducation,
    InformalEducation,
    ForeignLanguange,
    WorkExperience,
    SocialOrganization,
    Hobby,
    HealthHistory,
    OtherQuestion,
    Outfit,
    Signature,
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
      isLoading: "loading",
    }),
    step: {
      get() {
        return this.stepper_position;
      },
      set(value) {
        this.setStepperPosition(value);
      },
    },
    baseSteps() {
      return [
        {
          step: "1",
          // label: 'Data Pribadi',
          content: "personal-data",
          displayed: true,
        },
        {
          step: "2",
          // label: 'Kartu Identitas',
          content: "identity-card",
          displayed: true,
        },
        {
          step: "3",
          // label: 'Kontak',
          content: "contact",
          displayed: true,
        },
        {
          step: "4",
          // label: 'Kendaraan',
          content: "vehicle",
          displayed: true,
        },
        {
          step: "5",
          // label: 'Alamat',
          content: "b-address",
          displayed: true,
        },
        {
          step: "6",
          // label: 'Keluarga Inti',
          content: "b-family",
          displayed: true,
        },
        {
          step: "7",
          // label: 'Kontak Darurat',
          content: "emergency-contacts",
          displayed: true,
        },
        {
          step: "8",
          // label: 'Referensi',
          content: "reference",
          displayed: true,
        },
        {
          step: "9",
          // label: 'Pendidikan Formal',
          content: "formal-education",
          displayed: true,
        },
        {
          step: "10",
          // label: 'Pendidikan Informal',
          content: "informal-education",
          displayed: true,
        },
        {
          step: "11",
          // label: 'Pengetahuan Bahasa Asing',
          content: "foreign-languange",
          displayed: true,
        },
        {
          step: "12",
          // label: 'Pengalaman Kerja',
          content: "work-experience",
          displayed: true,
        },
        {
          step: "13",
          // label: 'Kontak',
          content: "social-organization",
          displayed: true,
        },
        {
          step: "14",
          // label: 'Kontak',
          content: "hobby",
          displayed: true,
        },
        {
          step: "15",
          // label: 'Kontak',
          content: "health-history",
          displayed: true,
        },
        {
          step: "16",
          // label: 'Kontak',
          content: "outfit",
          displayed: true,
        },
        {
          step: "17",
          // label: 'Kontak',
          content: "other-question",
          displayed: true,
        },
        {
          step: "18",
          // label: 'Kontak',
          content: "signature",
          displayed: true,
        },
      ];
    },
    steps() {
      const steps = [...this.baseSteps];

      return steps;
    },
  },
  methods: {
    ...mapMutations(["setFormStatus"]),
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    // stepStatus() {
    // 	var completed = null
    // 	if(localStorage.getItem("completed") === null) {
    // 		completed = false
    // 		localStorage.setItem("completed",JSON.stringify(completed))
    // 	}
    // },
    fetchApplicant() {
      this.$axios
        .get(`applicants/${this.applicant.id}`)
        .then((response) => {
          var applicant = response.data.data;
          this.setApplicant(applicant);
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });

          this.$router.push({ name: "landing" });
        })
        .finally(() => {
          // localStorage.removeItem("id");
          this.setStepperPosition(0);
        });
    },

    // async fetchApplicantDetail() {
    //   try {
    //     const response = await this.$axios.get(
    //       `applicants/` + this.applicant.id + `/applicant-detail`
    //     );
    //     this.applicant_details = response.data.data;
    //     // console.log(this.applicant_details);
    //   } catch (error) {
    //     let message = error.response.data.message;

    //     console.log(message);
    //   }
    // },
    // prev() {
    // 	this.setStepperPosition(this.stepper_position-1)
    // },
  },
};
</script>
